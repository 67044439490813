import React, { useMemo, useState } from "react";

//REDUX
import { useSelector } from 'react-redux';

// !!! İCONLAR !!!
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash,faCheck,faXmark } from '@fortawesome/free-solid-svg-icons';

// PAGES
import AdminCompanyUpdate from "../CompanyTable/AdminCompanyUpdate";
import AdminCompanyDelete from "./AdminCompanyDelete";

// !!! DATATABLE İMPORTLAR !!!
import DataTable from "react-data-table-component";
import FilterComponent from "./CompanyFilterComponent";



const Table = props => {

  const tumKurumListe = useSelector(state => state.tumKurumListe);

  const [updateBilgiler, setUpdateBilgiler] = useState();

  const updateClickHandler = (updateAlan) => {
    console.log("Güncelle", updateAlan);
    setUpdateBilgiler(updateAlan)
  }

  //MODAL DEĞİŞKENLERİ
  const [show, setShow] = useState(false);

  const [selectedMultipleUpdatedItems, setSelectedMultipleUpdatedItems] = useState([]);

  const CompanyStatusCell = ({ row, selectedMultipleUpdatedItems }) => {
    const company = row;
    const [isActive, setIsActive] = useState(company.isActive || false);
  
  
    const handleCheckboxClick = () => {
      setIsActive((prevIsActive) => !prevIsActive);
  
      const rowId = company._id;
  
      const exists = selectedMultipleUpdatedItems.some(item => item.rowId === rowId);
  
      // selectedMultipleUpdatedItems.push({rowId,isActive})
      console.log(selectedMultipleUpdatedItems)
      console.log(isActive)
  
      if (exists) {
        const index = selectedMultipleUpdatedItems.findIndex(item => item.rowId === rowId);
        selectedMultipleUpdatedItems.splice(index, 1);
      } else {
        selectedMultipleUpdatedItems.push({ rowId, isActive: !isActive });
      }
    };
    return (
      <div
        className={`checkbox-div ${isActive ? 'checked' : ''}`}
        onClick={handleCheckboxClick}
      >
        <div className="checkbox-icon">{isActive ?
         <FontAwesomeIcon style={{color:"green"}} size="2x" icon={faCheck}></FontAwesomeIcon> 
         : 
         <FontAwesomeIcon style={{color:"red"}} size="2x" icon={faXmark}></FontAwesomeIcon>
         }</div>
      
      </div>


    );
  };

{/* <>
<FontAwesomeIcon style={{color:"red"}} size="2x" icon={faXmark}></FontAwesomeIcon>

<FontAwesomeIcon style={{color:"green"}} size="2x" icon={faCheck}></FontAwesomeIcon>
</>
    */}

  const columns = [
    {
      name: "Kurum Adı",
      selector: (row) => row.kurumAdı,
      sortable: true,
      grow: 1,
    },
    {
      name: "Şehir",
      selector: row => row.sehir,
      sortable: true,
      hide: "sm",
      grow: 1
    },
    {
      name: "Telefon No",
      selector: row => row.telefonNo,
      sortable: true,
      hide: "lg",
      grow: 1
    },
    {
      name: "Durum",
      button: true,
      cell: (row) => <CompanyStatusCell row={row} selectedMultipleUpdatedItems={selectedMultipleUpdatedItems} setSelectedMultipleUpdatedItems={setSelectedMultipleUpdatedItems} />,
    },
    {
      name: "İşlemler",
      button: true,
      cell: row =>
      (
        <>
          <div onClick={() => {
            updateClickHandler(row)
            setShow(true)
          }}>
            <AdminCompanyUpdate setShow={setShow} show={show} updateBilgiler={updateBilgiler}></AdminCompanyUpdate>
          </div>

            <AdminCompanyDelete deleteBilgiler={row} icon={faTrash}></AdminCompanyDelete>

        </>
      )
    }
  ];

  const [secilenSehir, setSecilenSehir] = React.useState("");
  const [secilenKurumTur, setSecilenKurumTur] = React.useState("");
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );


  const filterMethods = [
    (item => item.sehir.toLowerCase().includes(secilenSehir.toLowerCase())),
    (item => item.kurumTur.toLowerCase().includes(secilenKurumTur.toLowerCase())),
    (item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1)
  ]


  const filteredArray = tumKurumListe.filter((item) => {
    for (let i = 0; i < filterMethods.length; i++) {
      if (!filterMethods[i](item)) {
        return false
      }
    }
    return true
  })



  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value.toLocaleUpperCase('tr-TR'))}
        onClear={handleClear}
        filterText={filterText}
        setSecilenSehir={setSecilenSehir}
        setSecilenKurumTur={setSecilenKurumTur}
        secilenSehir={secilenSehir}
        selectedMultipleUpdatedItems={selectedMultipleUpdatedItems}
        
      />
    );
  }, [filterText, resetPaginationToggle, secilenSehir,selectedMultipleUpdatedItems]);

  return (
    <DataTable
      title="Kurum Listesi"
      columns={columns}
      data={filteredArray}
      defaultSortField="name"
      striped
      pagination
      subHeader
      subHeaderComponent={subHeaderComponent}
      paginationRowsPerPageOptions={[10, 25, 50, 100]}
      paginationPerPage={50}
      noDataComponent="Herhangi bir veriye ulaşılmadı"

      paginationComponentOptions={{
        rowsPerPageText: "Sayfa başına kayıt sayısı:"
      }}
    />
  );
};

export default Table;
