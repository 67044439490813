import { useEffect, useState,useCallback  } from "react";
import { useNavigate } from "react-router-dom";

// REDUX
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchVideoCozumListe } from '../../store/actions/actions';

// AXİOS
import axios from "axios";
import { videocozumekle, profilkurumbilgigetir } from "../../axios/axios";



// MODAL REACT-BOOTSTRAP
import { Modal } from "react-bootstrap";

// BİLDİRİM PAKETİ TOAST
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

// FONT AWASOME İCON PAKETİ
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faPlayCircle, faPlus, faSearch, faX } from '@fortawesome/free-solid-svg-icons';

// CSS
import "../css/Admin/AdminVideoSolution.css"
import styled from "styled-components";

// Data Güncelleme

import AdminVideoSolutionDataUpdateButton from "./AdminVideoSolutionDataUpdateButton";

import soruBulunamadıKapak from "../img/sorubulunamadı.jpg";


const Input = styled.input.attrs(props => ({
  type: "text",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;


const AdminVideoSolution = ({ userData }) => {

  const [videoCozumListe, setVideoCozumListe] = useState([]);

  //MODAL DEĞİŞKENLERİ
  const [show, setShow] = useState(false);
  const [selectedDataContent, setSelectedDataContent] = useState(null);
  const [fullDataContent, setFullDataContent] = useState([]);

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const [ikinciKaynakData, setIkinciKaynakData] = useState([]);
  const [ucuncuKaynakData, setUcuncuKaynakData] = useState([]);
  const [sonKaynakData, setSonKaynakData] = useState([]);

  const [questionTrue, setQuestionTrue] = useState([]);
  const [questionFalse, setQuestionFalse] = useState([]);
  const ucuncuCollectedNodes = [];
  const collectedNodes = [];

    // Öncelikle bir veri yapısı oluşturun
let preparedLastData = [];

const targetProgress = 80; // Hedef ilerleme



  // Search area

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    console.log(searchText)
  }, [searchText])

  const filteredVideoCozumListe = videoCozumListe
  .filter((data) =>
    data.path.toLowerCase().includes(searchText.toLowerCase())
  )
  .sort((a, b) => {
    // Path değerlerini parçalarına ayır
    const aParts = a.path.match(/(\d+)|([^\d]+)/g);
    const bParts = b.path.match(/(\d+)|([^\d]+)/g);

    // Parçaları karşılaştır
    for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
      const aPart = aParts[i];
      const bPart = bParts[i];

      // Sayısal kısımları sayısal olarak karşılaştır
      if (!isNaN(aPart) && !isNaN(bPart)) {
        const numA = parseInt(aPart, 10);
        const numB = parseInt(bPart, 10);
        if (numA !== numB) {
          return numA - numB;
        }
      } else {
        // Metin kısımları alfabetik olarak karşılaştır
        if (aPart !== bPart) {
          return aPart.localeCompare(bPart);
        }
      }
    }

    // Eğer tüm parçalar eşitse, uzunluklarına göre karşılaştır
    return aParts.length - bParts.length;
  });

  console.log(filteredVideoCozumListe)

  const [filteredVideoCozumLoading, setFilteredVideoCozumLoading] = useState(true);

  // FiltredVideoCozumListe varsa loading efektini kaldır.
  useEffect(() => {
    if (filteredVideoCozumListe.length > 0) {
      setFilteredVideoCozumLoading(false)
    }
  }, [filteredVideoCozumListe])


    // API KEY'LER
    const data = {
      action: process.env.REACT_APP_FERNUS_ACTION,
      node: process.env.REACT_APP_FERNUS_NODE,
      token: process.env.REACT_APP_FERNUS_TOKEN
    };


    const kaynakDataAl = async () => {

      try {
        setLoading(true)
        setProgress(0)
        const response = await axios.post('https://vektorelvideo.aciyayinlari.com.tr/api/action.php', data, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });

        console.log(response.data.nodes);

        // İkinci iç içe istek
        for (const ikincidata of response.data.nodes) {
          console.log(ikincidata);
          const ikinciIstekData = {
            action: 'get-node',
            node: ikincidata.id,
            token: data.token
          };

          try {
            const ikinciIstekResponse = await axios.post('https://vektorelvideo.aciyayinlari.com.tr/api/action.php', ikinciIstekData, {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            });

            console.log(ikinciIstekResponse.data);

            collectedNodes.push(ikinciIstekResponse.data);
            console.log(collectedNodes);
            setProgress((prevProgress) => prevProgress + (5 / collectedNodes.length));

          } catch (error) {
            console.error('Birinci istek hatası:', error);
            toast.error("Birinci istek hatası !")
          }
        }


        for (const ucuncuData of collectedNodes) {
          console.log(ucuncuData);
          for (const nodes of ucuncuData.nodes) {

            const ucuncuIstekData = {
              action: 'get-node',
              node: nodes.id,
              token: data.token
            };

            try {
              const ucuncuIstekResponse = await axios.post('https://vektorelvideo.aciyayinlari.com.tr/api/action.php', ucuncuIstekData, {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
              });

              console.log(ucuncuIstekResponse.data);

              ucuncuCollectedNodes.push(ucuncuIstekResponse.data);
              console.log(ucuncuCollectedNodes);

            } catch (error) {
              console.error('Üçüncü istek hatası:', error);
              toast.error("Üçüncü istek hatası !")
            }

          }
        }

              for(const truefalseData of ucuncuCollectedNodes){
                console.log(truefalseData);
                for (const nodes of truefalseData.nodes) {

                  if (nodes.is_questions === true) {
                    questionTrue.push(nodes);
                    console.log(questionTrue);
                    console.log("if true çalıştı !!!!!!!!!!!!!!!!")
                  }
                   else {
                    const questionFalseIstekData = {
                      action: 'get-node',
                      node: nodes.id,
                      token: data.token
                    };

                    try {
                      const questionFalseIstekResponse = await axios.post('https://vektorelvideo.aciyayinlari.com.tr/api/action.php', questionFalseIstekData, {
                        headers: {
                          'Content-Type': 'application/x-www-form-urlencoded'
                        }
                      });
                      questionFalseIstekResponse.data.nodes.map((nodes) => {
                        questionFalse.push(nodes);
                        console.log(nodes)
                        console.log(questionFalse);
                      })


                    } catch (error) {
                      console.error('Birinci istek hatası:', error);
                      toast.error("Birinci istek hatası !")
                    }
                  }
                }
              }




        // collectedNodes içindeki işlemleri gerçekleştirin
        // for (const node of collectedNodes) {
        //   console.log(node);
        //   for (const nodes of node.nodes) {
        //     console.log(nodes.is_questions)
        //     if (nodes.is_questions === true) {
        //       questionTrue.push(nodes);
        //       console.log(questionTrue);
        //       console.log("if çalıştı !!!!!!!!!!!!!!!!")
        //     }
        //      else {
        //       const questionFalseIstekData = {
        //         action: 'get-node',
        //         node: nodes.id,
        //         token: data.token
        //       };

        //       try {
        //         // console.log(questionFalseIstekData)
        //         const questionFalseIstekResponse = await axios.post('https://vektorelvideo.aciyayinlari.com.tr/api/action.php', questionFalseIstekData, {
        //           headers: {
        //             'Content-Type': 'application/x-www-form-urlencoded'
        //           }
        //         });
        //         questionFalseIstekResponse.data.nodes.map((nodes) => {
        //           questionFalse.push(nodes);
        //           // console.log(nodes)
        //           // console.log(questionFalse);
        //         })


        //       } catch (error) {
        //         console.error('Birinci istek hatası:', error);
        //         toast.error("Birinci istek hatası !")
        //       }
        //     }
        //   }
        // }

        ikinciKaynakData.push(...questionTrue, ...questionFalse);
        console.log(questionTrue)
        console.log(questionFalse)
        console.log(ikinciKaynakData);
        const stepProgress = ikinciKaynakData.length > 0 ? targetProgress / ikinciKaynakData.length : 0;

        for (const questions of ikinciKaynakData) {

          const questionsData = {
            action: 'get-source',
            source: questions.id,
            token: data.token
          };

          try {
            const questionsIstekDataResponse = await axios.post('https://vektorelvideo.aciyayinlari.com.tr/api/action.php', questionsData, {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            });

            sonKaynakData.push(questionsIstekDataResponse.data);
            setProgress((prevProgress) => prevProgress + (stepProgress));
            console.log(sonKaynakData)
          } catch (error) {
            console.log(error)
          }

        }


        // Diğer işlemleri buraya ekleyebilirsiniz
      } catch (error) {
        console.error('Ana istek hatası:', error);
        toast.error("İstek hatası oluştu, Lütfen tekrar deneyiniz yada sunucu sahibi ile iletişime geçiniz !")
      }

      const processSonKaynakData = async () => {
        for (const data of sonKaynakData) {
          let parts = data.path.split('/').slice(2);
          let currentLevel = preparedLastData;

          for (let index = 0; index < parts.length; index++) {
            let part = parts[index];
            let existingPart = currentLevel.find((p) => p.path === part);

            if (!existingPart) {
              let newPart = { path: part, nodes: [], id: data.parent };
              currentLevel.push(newPart);
              existingPart = newPart;
            }

            if (index === parts.length - 1) {
              existingPart.answers = data.answers;
              existingPart.contents = data.contents;
              existingPart.id = data.id;
              existingPart.parent = data.parent;
            }

            currentLevel = existingPart.nodes;
          }

        }

        console.log(preparedLastData);

        try {
          setLoading(false)
          setProgress(100)
          const res = await videocozumekle(preparedLastData);
          console.log(res);
          toast.success(`Kaynak güncelleme başarılı, ${res.data.length} adet yeni veri eklendi !`)
        } catch (err) {
          console.error(err);
          toast.error("Kaynak Güncelleme Başarısız !")
        }
      };

      // Fonksiyonu çağır
      processSonKaynakData();

    };



  // useEffect(() => {
  //   profilkurumbilgigetir(userData.kurumID)
  //     .then((res) => {
  //       console.log(res.data)
  //       setProfileCompanyInfo(res.data)
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //     })
  // }, [])

  useEffect(() => {
    console.log(selectedDataContent)
  }, [selectedDataContent])



  const selectedQuestion = selectedDataContent;

  // Önceki sorunun order_number'ını alıp bir önceki soruya geçme
  const handlePreviousQuestion = () => {
    const prevOrderNumber = parseInt(selectedQuestion.order_number) - 1;
    const prevIndex = fullDataContent[0].findIndex(question => parseInt(question.order_number) === prevOrderNumber);
    if (prevIndex >= 0) {
      setSelectedDataContent(fullDataContent[0][prevIndex]);
    }
  };

  // Sonraki sorunun order_number'ını alıp bir sonraki soruya geçme
  const handleNextQuestion = () => {
    const nextOrderNumber = parseInt(selectedQuestion.order_number) + 1;
    let nextIndex = fullDataContent[0].findIndex(question => parseInt(question.order_number) === nextOrderNumber);

    if (nextIndex < fullDataContent[0].length && nextIndex !== -1) {
      setSelectedDataContent(fullDataContent[0][nextIndex]);
    }
  };

  // Redux'tan state çekme
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchVideoCozumListe());
  }, [dispatch]);

  const videoCozumListeCagir = useSelector(state => state.videoCozumListe);

  useEffect(() => {
    setVideoCozumListe(videoCozumListeCagir);
    console.log(videoCozumListe)
  }, [dispatch, videoCozumListeCagir]);

    // Modal kapatma ve arkaplan karartmasını kapatma
  const handleClose = () => {
    setShow(false);
    var elements = document.getElementsByClassName('MuiBox-root')[0];
    var container = document.getElementsByClassName('container')[0];
    container.style.opacity = 1;
    elements.style.opacity = 1;
  };

  // Modal açma ve arkaplan karartmasını açma
  const handleShow = ((dataContent, fulldata) => {
    console.log(fulldata)
    console.log(dataContent)
    setSelectedDataContent(dataContent);
    setFullDataContent(fulldata)
    setShow(true);

    var elements = document.getElementsByClassName('MuiBox-root')[0];
    var container = document.getElementsByClassName('container')[0];
    container.style.opacity = 0.2;
    elements.style.opacity = 0.2;
  });



  const TreeNode = ({ data }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    let fulldata = [];

    const handleToggle = () => {
      setIsExpanded(!isExpanded);
    };

    if (data.contents) {
      fulldata.push(data.contents)
    }
    let currentDataTime = new Date();

    // console.log("render oldu")

    // useEffect(()=>{
    //   console.log(currentDataTime,isExpanded);
    // },[isExpanded])

    // console.log(data)
    return (
      <>

        <div className="denemeKaynak" key={data.id}>
          <button style={{ width: "32px" }} onClick={handleToggle}>
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
          </button>
          <h5>{data.path}</h5>
        </div>

        {isExpanded && (
          <div className="denemeKaynakBranslar">
            {data.nodes.map((childData) => (
              <TreeNode key={childData.id} data={childData} />
            ))}
          </div>
        )}

        {isExpanded && data.contents && (
          <div className="denemeKaynakSoruAlan" >
            {data.contents.map((dataContent) => (

              <div className="denemeKaynakSoruDiv" key={dataContent.id}>
                <h3 className="denemeKaynakSoruH">{dataContent.name + " " + dataContent.order_number}</h3>
                <FontAwesomeIcon className="denemeKaynakSoruIcon"
                  onClick={() => handleShow(dataContent, fulldata)} icon={faPlayCircle} size="2x"></FontAwesomeIcon>
              </div>


            ))}
          </div>
        )}

      </>

    );
  };

  return (
    <>
      <div className="home_page">

        <div className="searchFilterAlan">
          <FontAwesomeIcon className="headerSearchIcon" icon={faSearch}></FontAwesomeIcon>
          <Input
            type="text"
            placeholder=" Video Çözüm Ara..."
            className="headerFilter"
            value={searchText}
            onChange={((e) => {
              setSearchText(e.target.value)
            })}
          />

          <ClearButton
            className="headerClear"
            onClick={(() => {
              setSearchText("")
            })}>
            <FontAwesomeIcon icon={faX} />
          </ClearButton>

          {/* <button className="adminVideoSolutionDataUpdateButton ms-5" onClick={kaynakDataAl}>Kaynak Güncelle</button> */}
          <AdminVideoSolutionDataUpdateButton progress={progress} kaynakDataAl={kaynakDataAl}/>

        </div>




        <h1>Video Çözümler</h1>

        <div className="denemeKaynakDiv">
          <>
            {filteredVideoCozumLoading &&
              <div className="wrapper">
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
                <span>YÜKLENİYOR...</span>
              </div>}
            {!filteredVideoCozumLoading && (
              <div>
                {filteredVideoCozumListe.map((filteredData) => (
                  <div key={filteredData.path}>
                    <TreeNode
                      key={filteredData.path}
                      data={filteredData}
                      handleShow={handleShow}
                      show={show}
                      selectedDataContent={selectedDataContent}
                    />
                  </div>
                ))}
              </div>
            )}
          </>
          {show && selectedDataContent && (

            <Modal className="modal videoSolutionModalDialogModal"
              dialogClassName="modal-dialog videoSolutionModalDialog" show={show} onHide={handleClose}>

              <Modal.Header closeButton className="videoSolutionModalHeader">
                <Modal.Title>{selectedQuestion.name + " " + selectedQuestion.order_number}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="videoSolutionModalBody">
                {selectedQuestion.solved ?
                  <iframe
                    title={selectedQuestion.name}
                    width={"100%"}
                    height={"100%"}
                    allowFullScreen={true}
                    className="modalVideoSolutionIframe"

                    src={selectedQuestion.solved}>
                  </iframe>
                  :
                  <img className="videoSolutionQuestionNotFoundImg" src={soruBulunamadıKapak} />
                }

              </Modal.Body>
              <div className="videoSolutionModalQuestionArea videoSolutionModalFooter">
                <div className="videoSolutionModalPrevquestion">
                  <p> Önceki Soru </p>
                  <FontAwesomeIcon className="videoSolutionModalIcon" onClick={handlePreviousQuestion} size="2x" icon={faChevronLeft} ></FontAwesomeIcon>
                </div>
                <div className="videoSolutionModalNextquestion">
                  <p> Sonraki Soru </p>
                  <FontAwesomeIcon className="videoSolutionModalIcon" onClick={handleNextQuestion} size="2x" icon={faChevronRight}></FontAwesomeIcon>
                </div>

              </div>
            </Modal>


          )}
        </div>
      </div>
      <ToastContainer />
    </>

  );
};


export default AdminVideoSolution;